export default class ManageCapture {}

$(function () {
    toggleLeadGeneration()
    toogleNameCapturing()
    toogleEmailCapturing()
});

function toggleLeadGeneration() {
    $('#lead-generation').on('switchChange.bootstrapSwitch', (e, data) => {
        let isOn = e.target.checked
        let manageCaptureButtons = $('.manage-capture-buttons')

        if(isOn) {
            manageCaptureButtons.show("slow")
        }

        if(!isOn) {
            manageCaptureButtons.hide("slow")
        }
    })
}

function toogleNameCapturing() {
    $('.name-capturing').on('switchChange.bootstrapSwitch', (e, data) => {
        let isOn = e.target.checked
        let leadId = $(e.target).attr('data-lead-id')
    })
}

function toogleEmailCapturing() {
    $('.email-capturing').on('switchChange.bootstrapSwitch', (e, data) => {
        let isOn = e.target.checked
        let leadId = $(e.target).attr('data-lead-id')
    })
}