import $ from 'jquery'


function set_actions() {

    $('.js-save_post_settings').change(function (){

        let data = {};

        if($(this).data('post')) {

            let label = $(this).attr('name')

            data = {
                'post_id': $(this).data('post'),
            }

            if (label == 'email_capture') {
                data['email_capture'] = $(this).prop("checked")
            }

            if (label == 'name_capture'){
                data['name_capture'] = $(this).prop("checked")
            }

        } else {
            let download = $('#js-posts-settings-download')
            let count = $('#js-posts-settings-count')
            let email_capture = $('#js-posts-settings-email_capture')
            let name_capture = $('#js-posts-settings-name_capture')
            let post_id = $('#js-posts-post_id')

            data = {
                'post_id': post_id.val(),
                'download': download.prop("checked"),
                'number_of_plays': count.prop("checked"),
                'name_capture': name_capture.prop("checked"),
                'email_capture': email_capture.prop("checked")
            }
        }

        save_settings(data)
    })
}

function getCookie(name) {
   let cookieValue = null;
   if (document.cookie && document.cookie !== '') {
       const cookies = document.cookie.split(';');
       for (let i = 0; i < cookies.length; i++) {
           const cookie = cookies[i].trim();
           // Does this cookie string begin with the name we want?
           if (cookie.substring(0, name.length + 1) === (name + '=')) {
               cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
               break;
           }
       }
   }
   return cookieValue;
}

function save_settings(data) {

    console.log(data)

    $.ajax({
        type: 'POST',
        url: '/post/save/',
        headers: {
            "X-CSRFToken": getCookie("csrftoken")
        },
        data: data,
        success: function (retorno) {
            console.log(retorno);
        },
        error: function (retorno) {
            console.log(retorno);
        }
    });

}

export {
    set_actions
}