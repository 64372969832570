
export default class Settings {}

$(function () {
    toggleDisclaimer()
    fillWords()
});

function fillWords(){
    $('#js-site-name').keyup(function(){
        $('#js-site-name-input').html($('#js-site-name').val())
    })

    $('#js-email-type').on('change', function(){
        $('#js-email-type-input').html($('#js-email-type').val())
    })

    $('#js-disclaimer-text').keyup(function(){
        $('#js-disclaimer-text-input').html($('#js-disclaimer-text').val())
    })
}

function toggleDisclaimer() {
    $('#disclaimer').on('switchChange.bootstrapSwitch', (e, data) => {
        let isOn = e.target.checked

        if(isOn) $('#disclaimer-inputs').removeClass('invisible')
        else $('#disclaimer-inputs').addClass('invisible')
    })
}