import $ from 'jquery'

class Charts {}

export default Charts

const STREAM_COLOR = '#662F91'
const COMPLETE_STREAM_COLOR = '#ff6633'
const DOWNLOAD_COLOR = '#808080'
const LEAD_GENERATED_COLOR = '#66cc99'

var chart;

$(function () {

    if ($('#SingleChart').length) {
        let chart = $('#SingleChart')
        let post_id = chart.data('value')

        let date_to = $('#js-single-post-to').val()
        let date_from = $('#js-single-post-from').val()

        get_data(post_id, date_to, date_from)
    }

    $('#js-single-search').click(function (){

        let chart = $('#SingleChart')
        let post_id = chart.data('value')

        let date_to = $('#js-single-post-to').val()
        let date_from = $('#js-single-post-from').val()

        get_data(post_id, date_to, date_from)

    })

    $('#from').change(function (){

        let chart = $('#SingleChart')
        let post_id = chart.data('value')

        let date_to = $('#js-single-post-to').val()
        let date_from = $('#js-single-post-from').val()

        get_data(post_id, date_to, date_from)

    })
    setDashboardMonthlyChart()
})

function get_data(post_id = 0, date_to, date_from) {

    $.ajax({
        type: 'GET',
        url: '/reports/single_post/' + post_id + '/' +  date_to + '/' + date_from + '/',
        success: function (retorno) {

            if (chart) {
                chart.destroy();
            }

            setRequestPageLineChart(retorno)
        },
        error: function (retorno) {
            return false
        }
    });
}


/**
 * It creates a line chart with 4 datasets, each with a different color.
 * </code>
 */
function setRequestPageLineChart(data) {

    $('.js-downloads').html(data['total_downloads'])
    $('.js-streams').html(data['total_streams'])
    $('.js-completed_streams').html(data['total_completed_streams'])
    $('.js-leads').html(data['total_leads'])


    let labels = []
    let streams = []
    let completed_streams = []
    let downloads = []
    let leads = []

    for (const key in data['data']) {
        labels.push(key)

        streams.push(data['data'][key]['streams'])
        downloads.push(data['data'][key]['downloads'])
        completed_streams.push(data['data'][key]['completed_streams'])
        leads.push(data['data'][key]['leads'])
    }

    const response = {
        labels: labels,
        datasets: [
            {
                label: 'Streams',
                backgroundColor: STREAM_COLOR,
                borderColor: STREAM_COLOR,
                data: streams,
            },
            {
                label: 'Complete streams',
                backgroundColor: COMPLETE_STREAM_COLOR,
                borderColor: COMPLETE_STREAM_COLOR,
                data: completed_streams,
            },
            {
                label: 'Downloads',
                backgroundColor: DOWNLOAD_COLOR,
                borderColor: DOWNLOAD_COLOR,
                data: downloads,
            },
            {
                label: 'Leads generated',
                backgroundColor: LEAD_GENERATED_COLOR,
                borderColor: LEAD_GENERATED_COLOR,
                data: leads,
            },
        ]
    };

    const config = {
        type: 'line',
        data: response,
        options: {
            plugins: {
                legend: {
                    position: 'bottom'
                }
            },
            scales: {
                y: {
                    beginAtZero:true
                }
            }
        }
    };

   chart = new Chart(
        document.getElementById('SingleChart'),
        config
    );
}

/**
 * It creates a line chart with 4 datasets, each with a different color.
 * </code>
 */
function setDashboardMonthlyChart() {
    const labels = [
        '01/2022',
        '02/2022',
        '03/2022',
        '04/2022',
        '05/2022',
        '07/2022',
        '08/2022',
        '09/2022',
        '10/2022',
        '11/2022',
        '12/2022'
    ];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Streams',
                backgroundColor: STREAM_COLOR,
                borderColor: STREAM_COLOR,
                data: getRandomChartData(0, 50, 12),
            },
            {
                label: 'Complete streams',
                backgroundColor: COMPLETE_STREAM_COLOR,
                borderColor: COMPLETE_STREAM_COLOR,
                data: getRandomChartData(0, 50, 12),
            },
            {
                label: 'Leads generated',
                backgroundColor: LEAD_GENERATED_COLOR,
                borderColor: LEAD_GENERATED_COLOR,
                data: getRandomChartData(0, 50, 12),
            },
        ]
    };

    const config = {
        type: 'line',
        data: data,
        options: {
            plugins: {
                legend: {
                    position: 'bottom'
                }
            }
        }
    };

    new Chart(
        document.getElementById('monthlyChart'),
        config
    );
}

function getRandomChartData(min, max, qtd) {
    let data = []

    for (let index = 0; index < qtd; index++) {
        data.push(Math.random() * (max - min) + min);
    }

    return data;
}