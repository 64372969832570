import $ from 'jquery'
import { set_actions } from './post';
import { change_colors } from './player';

class Custom {

}

export default Custom

var swalVooozer;

$(document).ready(function () {
    setMasks()
    setMultipleSelects()
    setDataTables()
    setDatePicker()
    setTootltip()
    setBootstrapSwitch()
    checkStripeOrder()

    set_actions()
    change_colors()

    /* Setting the swalVooozer variable to a mixin of the SweetAlert2 library. */
    swalVooozer = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-hover color-vooozer',
            denyButton: 'btn btn-primary btn-hover color-default-vooozer'
        },
        buttonsStyling: false
    })

    countCredits()
})

function countCredits() {

    let words=0
    let totalCreditsObject = $('.js-total-credits')
    let totalCredits = totalCreditsObject.data('value')

    $('.js-count-credits').click(function(){
        if ($(this).is(":checked")) {
            alert('home is checked');
        } else {
            alert('home is not checked');
        }
    })

}

function checkStripeOrder() {

    let order = $("#stripe-order")

    // Handle any errors from Checkout
    var handleResult = function (result) {
        if (result.error) {
            console.log(result.error.message)
        }
    };

    if (order.data('key')) {

        let key = order.data('key')

        const stripe = Stripe(key);

         // Call Stripe.js method to redirect to the new Checkout page
        stripe.redirectToCheckout({
            sessionId: order.val()
        }).then(handleResult);

    }


}

/**
 * This function sets the data tables.
 */
function setDataTables() {
    $('.js-datatables').DataTable(
        {
            dom: '<"top"f>t<"bottom"pl>',
        }
    )
}

/**
 * For each input element with the data-bootstrap-switch attribute, set the bootstrap switch state to
 * the value of the checked attribute.
 */
function setBootstrapSwitch() {
    $("input[data-bootstrap-switch]").each(function () {
        $(this).bootstrapSwitch('state', $(this).prop('checked'))
    })
}

/**
 * This function sets the tooltip for the element with the attribute data-toggle="tooltip" to the value
 * of the attribute data-original-title.
 */
function setTootltip() {
    $('[data-toggle="tooltip"]').tooltip()
}

/**
 * This function sets the multiple select boxes to be select2 boxes.
 */
function setMultipleSelects() {
    $('.select2-input').select2()
}

/**
 * Set the datepicker options and then apply them to the datepicker.
 */
function setDatePicker() {
    let datepicker_options = {
        format: 'DD/MM/YYYY',
        date: moment().format()
    }

    //Date picker
    $('.datetimepicker-input').datetimepicker(datepicker_options)
}

/**
 * This function sets the masks for the price and the js-only-number classes.
 */
function setMasks() {
    $('#price').maskMoney()
    $('.js-only-number').mask("#")
}

/**
 * It loops through all the inputs in the form, and if any of the inputs have a value that is different
 * from the original value, it will show a confirmation dialog.
 * @param formSelector - The selector of the form you want to validate.
 * @param callback - The function to be called after the user has confirmed or denied the alert.
 */
function validateFormAlteration(formSelector, callback) {
    $(formSelector + ' input').each(
        (index, element) => {
            let inputValue = $(element).val()
            let originalInputValue = $(element).attr('data-original')

            if (inputValue != originalInputValue) {
                swalVooozer.fire({
                    title: 'Your changes were not saved, do you want to continue?',
                    showDenyButton: true,
                    confirmButtonText: 'Continue',
                    denyButtonText: `No`,
                }).then((result) => {
                    callback(result)
                })

                return true
            }
        }
    )
}

/* Adding a click event to the element with the class collapsed. When the element is clicked, it will
toggle the class rotated on the child element. */
$('.collapsed').click(function () {
    $(this).children('i').toggleClass('rotated')
})

/* Adding an event listener to the element with the id audio-information-modal. When the modal is
hidden, it will call the validateFormAlteration function. */
$('#audio-information-modal').on('hide.bs.modal', function (event) {
    validateFormAlteration('#audio_information', modalFormSaveConfirmation)
})

/**
 * If the user confirms the modal, then show a toast. If the user denies the modal, then show the
 * modal.
 * @param result - The result of the modal form.
 * @returns The result is an object with two properties: isConfirmed and isDenied.
 */
function modalFormSaveConfirmation(result) {
    if (!result) return false

    const {
        isConfirmed,
        isDenied
    } = result

    if (isConfirmed) {
        swalVooozer.fire('Changes are not saved', '', 'info')
    } else if (isDenied) {
        $('#audio-information-modal').modal('show')
    }
}
